import styles from "./KayitMobil.module.css";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };

const KayitMobil = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const navigate = useNavigate();

  const [isim, setIsim] = useState('');
  const [email, setEmail] = useState('');
  const [telefon, setTelefon] = useState('');
  const [password, setPassword] = useState('');
  const [uyari, setUyari] = useState('');
  const [ref, setRef] = useState('KRC');

  const kayittamamla = async (idsi) => {
    const now = new Date();
    try {
      await addDoc(collection(db, "users"), {
        user: isim,
        telefon: telefon,
        email: email,
        sifres: password,
        img: "",
        üyelik: "3",
        userid: idsi,
        üyelik_tarihi: now
      });
      refkayit(idsi);
    } catch (error) {
      console.log("hata:", error);
    }
  };

  const refkayit = async (idsi) => {
    const now = new Date();
    const randomNumber = Math.floor(Math.random() * 1000000) + 1;
    try {
      await addDoc(collection(db, "referanslarim"), {
        durum: "Yeni Kayıt",
        islemid: randomNumber,
        kazanc: 0,
        kullanan: isim,
        kullananid: idsi,
        kod: ref,
        tarih: now
      });
      navigate('/panel');
    } catch (error) {
      console.error("Referans kaydı hatası:", error);
    }
  };

  const registrationHandler = async (event) => {
    event.preventDefault();
    setUyari('');

    try {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(response.user, {
        displayName: isim
      });
      
      await kayittamamla(response.user.uid);
      
      localStorage.setItem('user', JSON.stringify(response.user));
      navigate('/panel');
    } catch (error) {
      let hataMessaji = "Üyelik bilgilerinizi kontrol edin!";
      
      if (error.code === 'auth/email-already-in-use') {
        hataMessaji = "Bu e-posta adresi zaten kullanımda.";
      } else if (error.code === 'auth/invalid-email') {
        hataMessaji = "Geçersiz e-posta adresi.";
      } else if (error.code === 'auth/weak-password') {
        hataMessaji = "Şifre çok zayıf. Lütfen daha güçlü bir şifre seçin.";
      } else if (error.code === 'auth/operation-not-allowed') {
        hataMessaji = "E-posta/şifre girişi etkinleştirilmemiş.";
      } else if (error.code === 'auth/missing-password') {
        hataMessaji = "Şifre giriniz (+6 Karakter)";
      }
      
      setUyari(hataMessaji);
      console.error("Hata:", error);
    }
  };

  const girisYapHandler = () => {
    navigate('/giris');
  };


  return (
    <div className={styles.kayitMobil}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-12@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textParent}>
          <b className={styles.text1}>Yeni Nesil Analiz Platformu</b>
          <div className={styles.textGroup}>
            <b className={styles.text2}>Kayıt Ol</b>
            <div className={styles.text3}>
              Şimdi hemen hesabınızı oluşturup kullanmaya ücretsiz başlayın !
            </div>
            <div className={styles.selectWrapper}>
              <div className={styles.select}>
                <div className={styles.selectChild} />
                <div className={styles.textContainer}>
                  <div className={styles.text4}>Kayıt Ol</div>
                </div>
                <div className={styles.text5} onClick={girisYapHandler}>Giriş</div>
                </div>
            </div>
            <div className={styles.referralCodeOptional}>
              <div className={styles.component4Wrapper}>
                <div className={styles.component4Wrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <div className={styles.text6}>Full name</div>
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <div className={styles.text7}>Erfan amade</div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
                  type="text"
                  value={isim}
                  onChange={(e) => setIsim(e.target.value)}
                  placeholder="İsim"
                  className={styles.text8}
                />
                  <img
                    className={styles.component4Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
              </div>
              <img
                className={styles.referalCodeIcon}
                alt=""
                src="/referalcode.svg"
              />
            </div>
            <div className={styles.referralCodeOptional}>
              <div className={styles.component4Wrapper}>
                <div className={styles.frameGroup}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.text6}>Full name</div>
                    <img
                      className={styles.frameItem}
                      alt=""
                      src="/line-187.svg"
                    />
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="/group-20468.svg"
                    />
                  </div>
                  <div className={styles.text7}>Erfan amade</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-20470.svg"
                  />
                  <img className={styles.lineIcon} alt="" src="/line-338.svg" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Adresi"
                  className={styles.text8}
                />                <img
                  className={styles.component4Child}
                  alt=""
                  src="/line-188.svg"
                />
              </div>
              <img className={styles.checkIcon} alt="" src="/check.svg" />
              <img className={styles.mailIcon} alt="" src="/mailicon.svg" />
            </div>
            <div className={styles.referralCodeOptional}>
              <div className={styles.component4Wrapper}>
                <div className={styles.frameGroup}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.text6}>Full name</div>
                    <img
                      className={styles.frameItem}
                      alt=""
                      src="/line-187.svg"
                    />
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="/group-20468.svg"
                    />
                  </div>
                  <div className={styles.text7}>Erfan amade</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-20470.svg"
                  />
                  <img className={styles.lineIcon} alt="" src="/line-338.svg" />
                </div>
                <input
                  type="tel"
                  value={telefon}
                  onChange={(e) => setTelefon(e.target.value)}
                  placeholder="Telefon Numarası"
                  className={styles.text8}
                />
                <img
                  className={styles.component4Child}
                  alt=""
                  src="/line-188.svg"
                />
              </div>
              <img className={styles.checkIcon} alt="" src="/check.svg" />
              <img className={styles.phoneIcon} alt="" src="/phone@2x.png" />
            </div>
            <div className={styles.referralCodeOptional}>
              <div className={styles.component4Wrapper}>
                <div className={styles.frameGroup}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.text6}>Full name</div>
                    <img
                      className={styles.frameItem}
                      alt=""
                      src="/line-187.svg"
                    />
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="/group-20468.svg"
                    />
                  </div>
                  <div className={styles.text7}>Erfan amade</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-20470.svg"
                  />
                  <img className={styles.lineIcon} alt="" src="/line-338.svg" />
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Şifre"
                  className={styles.text8}
                />
                <img
                  className={styles.component4Child}
                  alt=""
                  src="/line-188.svg"
                />
              </div>
              <img
                className={styles.passwordIcon}
                alt=""
                src="/passwordicon.svg"
              />
            </div>
            <div className={styles.button}>
              <div className={styles.buttonChild} />
              <div className={styles.rectangleParent2}>
                <div className={styles.groupChild} />
                <img className={styles.frameIcon} alt="" src="/frame.svg" />
              </div>
              <button className={styles.button} onClick={registrationHandler}>

              <div className={styles.text18}>Kayıt Ol</div>
              </button>
            </div>
            {uyari && <div className={styles.text19}>Uyarı !! {uyari}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KayitMobil;
