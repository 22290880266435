import styles from "./DerinlikWindow.module.css";
import React, { useEffect, useState,useRef,useCallback,useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot,doc,setDoc,increment,getDoc , serverTimestamp,updateDoc} from "firebase/firestore"; 

 
const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };
  

const DerinlikWindow = () => {



    const [hisseKodu, setHisseKodu] = useState(() => {
        // Önce localStorage'dan son aranan hisseyi al
        const sonArananHisse = localStorage.getItem('sonArananHisse');
        // Eğer varsa onu kullan, yoksa 'sonSeciliHisse'yi kontrol et
        // O da yoksa "ASELS" kullan
        return sonArananHisse || localStorage.getItem('sonSeciliHisse') || "ASELS";
      });



    const [yedek, setyedek] = useState([
        "A1CAP",
        "ACSEL",
        "ADEL",
        "ADESE",
        "ADGYO",
        "AEFES",
        "AFYON",
        "AGESA",
        "AGHOL",
        "AGROT",
        "AGYO",
        "AHGAZ",
        "AKBNK",
        "AKCNS",
        "AKENR",
        "AKFGY",
        "AKFYE",
        "AKGRT",
        "AKMGY",
        "AKSA",
        "AKSEN",
        "AKSGY",
        "AKSUE",
        "AKYHO",
        "ALARK",
        "ALBRK",
        "ALCAR",
        "ALCTL",
        "ALFAS",
        "ALGYO",
        "ALKA",
        "ALKIM",
        "ALKLC",
        "ALMAD",
        "ALTINS1",
        "ALTNY",
        "ALVES",
        "ANELE",
        "ANGEN",
        "ANHYT",
        "ANSGR",
        "ARASE",
        "ARCLK",
        "ARDYZ",
        "ARENA",
        "ARSAN",
        "ARTMS",
        "ARZUM",
        "ASELS",
        "ASGYO",
        "ASTOR",
        "ASUZU",
        "ATAGY",
        "ATAKP",
        "ATATP",
        "ATEKS",
        "ATLAS",
        "ATSYH",
        "AVGYO",
        "AVHOL",
        "AVOD",
        "AVPGY",
        "AVTUR",
        "AYCES",
        "AYDEM",
        "AYEN",
        "AYES",
        "AYGAZ",
        "AZTEK",
        "BAGFS",
        "BAKAB",
        "BALAT",
        "BANVT",
        "BARMA",
        "BASCM",
        "BASGZ",
        "BAYRK",
        "BEGYO",
        "BERA",
        "BEYAZ",
        "BFREN",
        "BIENY",
        "BIGCH",
        "BIMAS",
        "BINHO",
        "BIOEN",
        "BIZIM",
        "BJKAS",
        "BLCYT",
        "BMSCH",
        "BMSTL",
        "BNTAS",
        "BOBET",
        "BORLS",
        "BORSK",
        "BOSSA",
        "BRISA",
        "BRKO",
        "BRKSN",
        "BRKVY",
        "BRLSM",
        "BRMEN",
        "BRSAN",
        "BRYAT",
        "BSOKE",
        "BTCIM",
        "BUCIM",
        "BURCE",
        "BURVA",
        "BVSAN",
        "BYDNR",
        "CANTE",
        "CASA",
        "CATES",
        "CCOLA",
        "CELHA",
        "CEMAS",
        "CEMTS",
        "CEOEM",
        "CIMSA",
        "CLEBI",
        "CMBTN",
        "CMENT",
        "CONSE",
        "CONSER",
        "COSMO",
        "CRDFA",
        "CRFSA",
        "CUSAN",
        "CVKMD",
        "CWENE",
        "DAGHL",
        "DAGI",
        "DAPGM",
        "DARDL",
        "DCTTR",
        "DENGE",
        "DERHL",
        "DERIM",
        "DESA",
        "DESPC",
        "DEVA",
        "DGATE",
        "DGGYO",
        "DGNMO",
        "DIRIT",
        "DITAS",
        "DMRGD",
        "DMSAS",
        "DNISI",
        "DOAS",
        "DOBUR",
        "DOCO",
        "DOFER",
        "DOGUB",
        "DOHOL",
        "DOKTA",
        "DURDO",
        "DYOBY",
        "DZGYO",
        "EBEBK",
        "ECILC",
        "ECZYT",
        "EDATA",
        "EDIP",
        "EFORC",
        "EGEEN",
        "EGEPO",
        "EGGUB",
        "EGPRO",
        "EGSER",
        "EKGYO",
        "EKIZ",
        "EKOS",
        "EKSUN",
        "ELITE",
        "EMKEL",
        "EMNIS",
        "ENERY",
        "ENJSA",
        "ENKAI",
        "ENSRI",
        "ENTRA",
        "EPLAS",
        "ERBOS",
        "ERCB",
        "EREGL",
        "ERSU",
        "ESCAR",
        "ESCOM",
        "ESEN",
        "ETILR",
        "ETYAT",
        "EUHOL",
        "EUKYO",
        "EUPWR",
        "EUREN",
        "EUYO",
        "EYGYO",
        "FADE",
        "FENER",
        "FLAP",
        "FMIZP",
        "FONET",
        "FORMT",
        "FORTE",
        "FRIGO",
        "FROTO",
        "FZLGY",
        "GARAN",
        "GARFA",
        "GEDIK",
        "GEDZA",
        "GENIL",
        "GENTS",
        "GEREL",
        "GESAN",
        "GIPTA",
        "GLBMD",
        "GLCVY",
        "GLRYH",
        "GLYHO",
        "GMTAS",
        "GOKNR",
        "GOLTS",
        "GOODY",
        "GOZDE",
        "GRNYO",
        "GRSEL",
        "GRTRK",
        "GSDDE",
        "GSDHO",
        "GSRAY",
        "GUBRF",
        "GWIND",
        "GZNMI",
        "HALKB",
        "HATEK",
        "HATSN",
        "HDFGS",
        "HEDEF",
        "HEKTS",
        "HKTM",
        "HLGYO",
        "HOROZ",
        "HRKET",
        "HTTBT",
        "HUBVC",
        "HUNER",
        "HURGZ",
        "ICBCT",
        "ICUGS",
        "IDGYO",
        "IEYHO",
        "IHAAS",
        "IHEVA",
        "IHGZT",
        "IHLAS",
        "IHLGM",
        "IHYAY",
        "IMASM",
        "INDES",
        "INFO",
        "INGRM",
        "INTEM",
        "INVEO",
        "INVES",
        "IPEKE",
        "ISATR",
        "ISBIR",
        "ISBTR",
        "ISCTR",
        "ISDMR",
        "ISFIN",
        "ISGSY",
        "ISGYO",
        "ISKPL",
        "ISKUR",
        "ISMEN",
        "ISSEN",
        "ISYAT",
        "IZENR",
        "IZFAS",
        "IZINV",
        "IZMDC",
        "JANTS",
        "KAPLM",
        "KAREL",
        "KARSN",
        "KARTN",
        "KARYE",
        "KATMR",
        "KAYSE",
        "KBORU",
        "KCAER",
        "KCHOL",
        "KENT",
        "KERVN",
        "KERVT",
        "KFEIN",
        "KGYO",
        "KIMMR",
        "KLGYO",
        "KLKIM",
        "KLMSN",
        "KLNMA",
        "KLRHO",
        "KLSER",
        "KLSYN",
        "KMPUR",
        "KNFRT",
        "KOCMT",
        "KONKA",
        "KONTR",
        "KONYA",
        "KOPOL",
        "KORDS",
        "KOTON",
        "KOZAA",
        "KOZAL",
        "KRDMA",
        "KRDMB",
        "KRDMD",
        "KRGYO",
        "KRONT",
        "KRPLS",
        "KRSTL",
        "KRTEK",
        "KRVGD",
        "KSTUR",
        "KTLEV",
        "KTSKR",
        "KUTPO",
        "KUVVA",
        "KUYAS",
        "KZBGY",
        "KZGYO",
        "LIDER",
        "LIDFA",
        "LILAK",
        "LINK",
        "LKMNH",
        "LMKDC",
        "LOGO",
        "LRSHO",
        "LUKSK",
        "MAALT",
        "MACKO",
        "MAGEN",
        "MAKIM",
        "MAKTK",
        "MANAS",
        "MARBL",
        "MARKA",
        "MARTI",
        "MAVI",
        "MEDTR",
        "MEGAP",
        "MEGMT",
        "MEKAG",
        "MEPET",
        "MERCN",
        "MERIT",
        "MERKO",
        "METRO",
        "METUR",
        "MGROS",
        "MHRGY",
        "MIATK",
        "MIPAZ",
        "MMCAS",
        "MNDRS",
        "MNDTR",
        "MOBTL",
        "MOGAN",
        "MPARK",
        "MRGYO",
        "MRSHL",
        "MSGYO",
        "MTRKS",
        "MTRYO",
        "MZHLD",
        "NATEN",
        "NETAS",
        "NIBAS",
        "NTGAZ",
        "NTHOL",
        "NUGYO",
        "NUHCM",
        "OBAMS",
        "OBASE",
        "ODAS",
        "ODINE",
        "OFSYM",
        "ONCSM",
        "ONRYT",
        "ORCAY",
        "ORGE",
        "ORMA",
        "OSMEN",
        "OSTIM",
        "OTKAR",
        "OTTO",
        "OYAKC",
        "OYAYO",
        "OYLUM",
        "OYYAT",
        "OZGYO",
        "OZKGY",
        "OZRDN",
        "OZSUB",
        "OZYSR",
        "PAGYO",
        "PAMEL",
        "PAPIL",
        "PARSN",
        "PASEU",
        "PATEK",
        "PCILT",
        "PEHOL",
        "PEKGY",
        "PENGD",
        "PENTA",
        "PETKM",
        "PETUN",
        "PGSUS",
        "PINSU",
        "PKART",
        "PKENT",
        "PLTUR",
        "PNLSN",
        "PNSUT",
        "POLHO",
        "POLTK",
        "PRDGS",
        "PRKAB",
        "PRKME",
        "PRZMA",
        "PSDTC",
        "PSGYO",
        "QNBFB",
        "QNBFL",
        "QUAGR",
        "RALYH",
        "RAYSG",
        "REEDR",
        "RGYAS",
        "RNPOL",
        "RODRG",
        "ROYAL",
        "RTALB",
        "RUBNS",
        "RYGYO",
        "RYSAS",
        "SAFKR",
        "SAHOL",
        "SAMAT",
        "SANEL",
        "SANFM",
        "SANKO",
        "SARKY",
        "SASA",
        "SAYAS",
        "SDTTR",
        "SEGMN",
        "SEGYO",
        "SEKFK",
        "SEKUR",
        "SELEC",
        "SELGD",
        "SELVA",
        "SEYKM",
        "SILVR",
        "SISE",
        "SKBNK",
        "SKTAS",
        "SKYLP",
        "SKYMD",
        "SMART",
        "SMRTG",
        "SNGYO",
        "SNICA",
        "SNKRN",
        "SNPAM",
        "SODSN",
        "SOKE",
        "SOKM",
        "SONME",
        "SRVGY",
        "SUMAS",
        "SUNTK",
        "SURGY",
        "SUWEN",
        "TABGD",
        "TARKM",
        "TATEN",
        "TATGD",
        "TAVHL",
        "TBORG",
        "TCELL",
        "TDGYO",
        "TEKTU",
        "TERA",
        "TETMT",
        "TEZOL",
        "TGSAS",
        "THYAO",
        "TKFEN",
        "TKNSA",
        "TLMAN",
        "TMPOL",
        "TMSN",
        "TNZTP",
        "TOASO",
        "TRCAS",
        "TRGYO",
        "TRILC",
        "TSGYO",
        "TSKB",
        "TSPOR",
        "TTKOM",
        "TTRAK",
        "TUCLK",
        "TUKAS",
        "TUPRS",
        "TUREX",
        "TURGG",
        "TURSG",
        "UFUK",
        "ULAS",
        "ULKER",
        "ULUFA",
        "ULUSE",
        "ULUUN",
        "UMPAS",
        "UNLU",
        "USAK",
        "UZERB",
        "VAKBN",
        "VAKFN",
        "VAKKO",
        "VANGD",
        "VBTYZ",
        "VERTU",
        "VERUS",
        "VESBE",
        "VESTL",
        "VKFYO",
        "VKGYO",
        "VKING",
        "VRGYO",
        "YAPRK",
        "YATAS",
        "YAYLA",
        "YBTAS",
        "YEOTK",
        "YESIL",
        "YGGYO",
        "YGYO",
        "YIGIT",
        "YKBNK",
        "YKSLN",
        "YONGA",
        "YUNSA",
        "YYAPI",
        "YYLGD",
        "ZEDUR",
        "ZOREN",
        "ZRGYO"
    ]
      );
    

     
    
      const fetchStockNames = useCallback(async () => {
        try {
          const response = await axios.get('https://finans.mynet.com/borsa/hisseler/', {
            headers: { 'User-Agent': 'Mozilla/5.0' }
          });
          const root = parse(response.data);
          const names = root.querySelectorAll('table tbody tr')
            .map(row => {
              const nameElement = row.querySelector('td:first-child strong a');
              return nameElement ? nameElement.text.trim().split(' ')[0] : null;
            })
            .filter(Boolean);
    
          setSirketAdstam(names);
          setisimleri(names);
        } catch (error) {
          setSirketAdstam(yedek);
          setisimleri(yedek);
        }
      }, []);
    
    
      
      useEffect(() => {
        

        fetchStockNames();
      }, []);


      const wsRef = useRef(null);


      const fetchStockPrice = useCallback((ne) => {

        if (urlsi) {
          const url = 'wss://'+urlsi+':443';
          const tut = []
          wsRef.current = new WebSocket(url);
        
          wsRef.current.onopen = () => {
            wsRef.current.send(`anlik,${ne}`);
          };
        
          wsRef.current.onmessage = (event) => {
            try {
              const data = JSON.parse(event.data);
              tut.push(data[0].last,data[0].ask,data[0].dailyAmount,data[0].dailyVolume,data[0].dailyChangePercentage,data[0].dailyLowest,data[0].dailyHighest);
        
              setfveri(tut)
              fetchStockislem(ne)
        
            } catch (e) {
            } finally {
            }
          };
        
          wsRef.current.onerror = (error) => {
          };
        
          wsRef.current.onclose = (event) => {
          };
        }
      
      
      
      
      }, [])
      
      
      const fetchStockislem = useCallback((ne) => {
        
      
        const url = 'wss://'+urlsi+':443';
        wsRef.current = new WebSocket(url);
      
        wsRef.current.onopen = () => {
          wsRef.current.send(`islem,${ne}`);
        };
      
        wsRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            setalansatan([])
            setalansatan(data)
          } catch (e) {
          } finally {
          }
        };
      
        wsRef.current.onerror = (error) => {
        };
      
        wsRef.current.onclose = (event) => {
        };
      }, [])


      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
        const [count, setCount] = useState(0);

        const [fveri, setfveri] = useState([]);

  const [derinlikveri, setderinlikveri] = useState([]);

  const derinlik = (hisse) => {
    return new Promise((resolve, reject) => {


      const url = 'wss://'+urlsi+':443';
      
      const ws = new WebSocket(url);
      
      ws.onopen = () => {

        
          
          ws.send(`derinlik10,${secili}`);

       

      };
      
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          setderinlikveri(data)
          ws.close();
        } catch (e) {
          console.error('JSON parse error:', e);
          ws.close();
          
        } finally {
          ws.close();
          fetchStockPrice(secili)
        }
      };
      
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.close();
      };
      
      ws.onclose = (event) => {
      };
  
      setTimeout(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          reject(new Error('WebSocket connection timeout'));
        }
      }, 10000);
    });
  };



  const formattedDatetw = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('tr-TR', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24 saat formatı
      timeZone: 'Europe/Istanbul' // Türkiye için zaman dilimi
    });
  };

  const [secili, setsecili] = useState(hisseKodu);   

  const [urlsi, setUrlsi] = useState('investcodecompany.online');

   useEffect(() => {
    

        const fetchData = async () => {
          try {
       
        
            const q = query(
              collection(db, "sunucu")
            );
        
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              querySnapshot.forEach((docSnapshot) => {
                
    
                  setUrlsi(docSnapshot.data().url)
                
              });
            });
        
            return () => {
              unsubscribe();
            };
        
          } catch (error) {
           
          }
        };
        
        
          
        fetchData();
     
      }, []);
    

  
  
 

  const yeni_hisse = async (hisse) => {

    setsecili(hisse)

    try {
      // Create a document reference with the stock symbol as the ID
      const stockRef = doc(db, "searchedStocks", hisse);
  
      // Get the document
      const stockDoc = await getDoc(stockRef);
  
      if (stockDoc.exists()) {
        // If the document exists, update the counts
        await updateDoc(stockRef, {
          dailyCount: increment(1),
          weeklyCount: increment(1),
          monthlyCount: increment(1),
          lastSearched: serverTimestamp()
        });
      } else {
        // If the document doesn't exist, create it
        await setDoc(stockRef, {
          symbol: hisse,
          dailyCount: 1,
          weeklyCount: 1,
          monthlyCount: 1,
          lastSearched: serverTimestamp()
        });
      }
  
    } catch (error) {
      console.error('Error recording search:', error);
    }




    
   
    setderinliklist([])
    setalansatan([])  
    setfveri([])
    sethisveri([])
    setsecili(hisse)
    setaranan(hisse)
    setaramalistt([]);
    setTriggerEffect(prev => !prev); // Trigger state'i değiştirir ve useEffect'i tetikler
    
    // Son seçili hisseyi localStorage'a kaydet
    localStorage.setItem('sonSeciliHisse', hisse);

    

    
  
  }
    
  const [triggerEffect, setTriggerEffect] = useState(false);

    const [hisveri, sethisveri] = useState([]);
    
        const [derinliklist, setderinliklist] = useState([]);
    
        const [sirketadstam, setSirketAdstam] = useState([
        ]
        );



        const [aramalistt, setaramalistt] = useState([]);
    
        const [alansatan, setalansatan] = useState([]);
    
        const [aranan, setaranan] = useState('');
        
              

        useEffect(() => {  


            const updateData = async (abab,hisis) => {  
              if (urlsi) {
  
              try {  
                await Promise.all([  
                  derinlik(hisis),
                ]);  
              } catch (error) {  
                console.error(error);  
              }  
            }
            };  
            // İlk çağrıyı hemen yap
        updateData(hisseKodu);
      
        // 5 saniyede bir tekrarlayan bir interval oluştur
        const intervalId = setInterval(() => {
          updateData(hisseKodu);
        }, 5000); // 5000 milisaniye = 5 saniye
      
        // Temizleme fonksiyonu
        return () => {
          // Component unmount olduğunda veya bağımlılıklar değiştiğinde interval'i temizle
          clearInterval(intervalId);
        };
  
  
  
          }, [triggerEffect, navigation,urlsi]);  
        
          const getFullName = (code) => {
            const institution = financialInstitutions.find(item => item[1] === code);
            return institution ? institution[0] : code;
          };
        
          const financialInstitutions = [
            ["A1 Capital", "ACP"],
            ["Acar", "ACA"],
            ["Adabank", "ADA"],
            ["Ag Kiy. Mad.", "WCV"],
            ["Agabullion Kiy. Mad.", "VAC"],
            ["Ahlatci", "AYX"],
            ["Ahlatci Doviz Kiy. Mad. Yet. Mues.", "WBI"],
            ["Ahlatci Metal Rafineri", "WMS"],
            ["Ak Yatirim", "AKM"],
            ["Akbank", "AKB"],
            ["Akbank (Portfoy Saklama)", "APS"],
            ["Akdeniz Menkul", "AKD"],
            ["Aktif Yatirim Bankasi", "AFB"],
            ["Alan", "ALN"],
            ["ALB", "ALM"],
            ["Albaraka Turk Katilim", "ALK"],
            ["Albayrak Doviz Altin Yet. Mues.", "WEQ"],
            ["Alfa Menkul", "ALF"],
            ["Allbatross", "BAH"],
            ["Alnus", "ANC"],
            ["Alternatif", "AMK"],
            ["Alternatif Bank", "ANF"],
            ["Altinbas Kiy. Mad.", "WAI"],
            ["Altinkaynak Doviz Kiy. Mad. Yet. Mues.", "WAJ"],
            ["Anadolu", "ADY"],
            ["Anadolubank", "ADB"],
            ["Arap Turk Bankasi", "ARA"],
            ["Ata", "ATA"],
            ["Atanur Kuyumculuk", "WCS"],
            ["BAB Menkul", "BAB"],
            ["Bank Of America", "MLB"],
            ["Bankpozitif", "POZ"],
            ["Birlesik Fon Bankasi", "BYR"],
            ["Bist Buyin Market Operation", "XCH"],
            ["Bist Market Operation", "BIST"],
            ["Bist Market Operation - Pmd", "BISTP"],
            ["Bist Offical Auction Market Operation", "XRM"],
            ["Bizim", "BMK"],
            ["Blupay Menkul", "BLU"],
            ["Bonus Kiy. Mad.", "WFW"],
            ["Bulls Yatirim", "BLS"],
            ["Burgan", "EFG"],
            ["Burgan Bank", "BUR"],
            ["Burgan Bank (Yabanci)", "EBY"],
            ["Censa Menkul", "CSA"],
            ["Ceros Menkul", "CRM"],
            ["Citi", "CIM"],
            ["Citibank", "CIT"],
            ["Citibank Yabanci", "CIY"],
            ["Citak Yet. Mues.", "WBF"],
            ["Colendi", "COL"],
            ["Credit Suisse", "CSM"],
            ["Deha Menkul", "DHA"],
            ["Demas Kuyumculuk", "VAI"],
            ["Denge Doviz Kiy. Mad. Yet. Mues.", "WAE"],
            ["Deniz", "DZY"],
            ["Denizbank", "DNZ"],
            ["Denizbank (Portfoy Saklama)", "DNS"],
            ["Destek", "DTB"],
            ["Deutsche Bank", "DBI"],
            ["Deutsche Bank (Yabanci)", "DBY"],
            ["Diler Yatirim Bankasi", "DLR"],
            ["Dinamik", "DMK"],
            ["Doruk Doviz Kiy. Mad. Yet. Mues.", "WAR"],
            ["Ehg Yet. Mues.", "WGD"],
            ["Ekspres Menkul", "EKS"],
            ["Eliptik", "ELP"],
            ["Emeklilik Fonlari", "XEM"],
            ["Endeks Turev", "END"],
            ["Erciyes Menul", "ERY"],
            ["Eti Menkul", "ETM"],
            ["Euroclear Participant", "ECL"],
            ["Eximbank", "THR"],
            ["Fark-Toplam", "ZZX"],
            ["Fetih Doviz Kiy. Mad. Yet. Mues.", "WCU"],
            ["Fibabanka", "FBB"],
            ["Finans (Poftfoy Saklama)", "FNS"],
            ["Finansbank", "FIN"],
            ["Fours Kiy. Mad.", "VAO"],
            ["Galata", "GLT"],
            ["Garanti", "GRM"],
            ["Garanti Bankasi", "TGB"],
            ["Garanti Bankasi (Portfoy Saklama)", "GPS"],
            ["GCM", "GCM"],
            ["Gedik", "GDK"],
            ["GFC General", "GFC"],
            ["Global", "GLB"],
            ["Golden Global Yatirim Bankasi", "GGI"],
            ["Goldex Kuyumculuk", "VAM"],
            ["Goldtrans", "VAJ"],
            ["Gram Kiy. Mad.", "WIX"],
            ["Gsd Yatirim Bankasi", "GSB"],
            ["Guven Menkul", "GVN"],
            ["Guven Yet. Mues.", "WAM"],
            ["Hakan Yet. Mues.", "WGC"],
            ["Halk", "HLY"],
            ["Halk Bankasi", "THL"],
            ["Halk Bankasi (Portfoy Saklama)", "HLS"],
            ["Has Gumus Deg. Mad.", "WWL"],
            ["Has Kiy. Mad.", "WBZ"],
            ["Hazine Mustesarligi", "HZN"],
            ["Haznedar Yet. Mues.", "WLV"],
            ["HSBC", "HSY"],
            ["HSBC Bank", "HSB"],
            ["HSBC Bank (Yabanci)", "HYA"],
            ["IAR", "WAG"],
            ["IAR Doviz Kiy. Mad. Yet. Mues.", "WCF"],
            ["ICBC", "ICT"],
            ["ICBC Bank", "ICB"],
            ["Ikon Menkul", "IKN"],
            ["Info", "IYF"],
            ["Ing", "GNI"],
            ["Ing Bank", "GIN"],
            ["Ing Bank (Portfoy Saklama)", "GIP"],
            ["Integral", "INM"],
            ["Intl Hrm Kiy. Mad.", "VAH"],
            ["InvestAZ", "IAZ"],
            ["Is Bankasi (Portfoy Saklama)", "IPS"],
            ["Is Yatirim", "IYM"],
            ["Isbankasi", "TIB"],
            ["Isgold Altin Rafinerisi", "VAA"],
            ["Jp Morgan Chasebank", "TCM"],
            ["Kalkinma", "KMD"],
            ["Kare Yatirim", "KRE"],
            ["Kule Yet. Mues.", "WCT"],
            ["Kuveyt Turk", "KTK"],
            ["Marbas", "MRS"],
            ["Meksa", "MSA"],
            ["Menekse Yet. Mues.", "WCE"],
            ["Merkez Menkul", "MRZ"],
            ["Merkez Yet. Mues.", "WAY"],
            ["Metro", "MTY"],
            ["Midas", "MDS"],
            ["Mkk Clearance System", "MKK"],
            ["Morgan Stanley", "MSI"],
            ["Mufg Bank", "MUF"],
            ["Muhafiz Kiy. Mad.", "VAQ"],
            ["Nadir Doviz Kiy. Mad. Yet. Mues.", "WBA"],
            ["NCM Investment", "NOR"],
            ["Neta Yatirim", "NTA"],
            ["Nil Kiy. Mad.", "WZR"],
            ["Nizip Doviz Kiy. Mad. Ticareti Yet. Mues.", "WCL"],
            ["Nmglobal Kiy. Mad.", "VAL"],
            ["Nurol Menkul", "NUR"],
            ["Nurol Yatirim Bankasi", "NYB"],
            ["Odea Bank", "ODB"],
            ["Oner Menkul", "ONR"],
            ["Osmanli", "OMD"],
            ["Osmanli Doviz Altin Yet. Mues.", "WTQ"],
            ["Osmanli Kiy. Mad.", "WSX"],
            ["Oyak", "OYA"],
            ["Ozak Kiy. Mad.", "VAB"],
            ["Papara", "POL"],
            ["Pasha Yatirim Bankasi", "PBT"],
            ["Phillipcapital", "PHC"],
            ["Piramit", "PIT"],
            ["Pirim Menkul", "PMK"],
            ["Pmd Official Auction Buyin", "XPD"],
            ["Prv Market Participant", "_PRV"],
            ["Qnb Finans", "FNY"],
            ["Raymond James", "RJS"],
            ["Renaissance Capital", "REN"],
            ["Rona Doviz Kiy. Mad. Yet. Mues.", "WAB"],
            ["Royal Bank Of Scotland", "RBS"],
            ["Saglamoglu Yet. Mues.", "VAN"],
            ["Saxo Capital", "SAX"],
            ["Say Kiy. Mad.", "WVQ"],
            ["Sayilgan Menkul", "SYG"],
            ["Seker", "SKY"],
            ["Sekerbank", "SEK"],
            ["Sembol Yabanci Para Kiy. Mad. Yet. Mues.", "WAF"],
            ["Soymen Menkul", "SOE"],
            ["Standart Kiy. Mad.", "WNN"],
            ["Strateji", "STJ"],
            ["T.Kalkinma Ve Yatirim Bankasi", "KLN"],
            ["Tacirler", "TAC"],
            ["Takasbank", "TVS"],
            ["Takasbank Collateral Valuation", "TTT"],
            ["Takasbank In Sukuk Repo", "XTK"],
            ["Takasbank Swap", "TVSSW"],
            ["Taksim Menkul", "TKS"],
            ["TCMB", "MB"],
            ["TEB", "TEB"],
            ["TEB (Menkul Kiymet Hizmetleri)", "TYS"],
            ["TEB (Portfoy Saklama)", "TEP"],
            ["TEB Yatirim", "TBY"],
            ["Tekstil Bankasi", "TES"],
            ["Tera", "TRA"],
            ["TFG Istanbul", "TFG"],
            ["Toros Menkul", "TOR"],
            ["Trader Kiy. Mad.", "VAE"],
            ["Trive", "TVM"],
            ["Troy Kiy. Mad.", "WAK"],
            ["TSKB", "TSK"],
            ["Turan Kiy. Mad.", "WAL"],
            ["Turkish", "TKY"],
            ["Turkish", "TMD"],
            ["Turkish Bank", "TBA"],
            ["Turkiye Emlak", "EKT"],
            ["Turkiye Finans", "FKT"],
            ["Turkland Bank", "TBN"],
            ["UBS Menkul", "UBS"],
            ["Uguras Kiy. Mad.", "WBN"],
            ["Unicredit", "UCI"],
            ["Unlu", "UNS"],
            ["Urus Kiy. Mad.", "WEW"],
            ["Vakif", "VKY"],
            ["Vakif Katilim Bankasi", "VKF"],
            ["Vakiflar Bankasi", "TVB"],
            ["Vakiflar Bankasi (Portfoy Saklama)", "VBS"],
            ["Venbey", "VNB"],
            ["Vesta Menkul", "VST"],
            ["XXX Menkul Kiymetler", "XXX"],
            ["Yapi Kredi", "YKR"],
            ["Yapi Kredi Bankasi", "YKB"],
            ["Yapi Kredi Bankasi (Portfoy Saklama)", "YKC"],
            ["Yatirim Fonlari", "XYF"],
            ["Yatirim Ortakliklari", "XYO"],
            ["YFAS", "YAT"],
            ["Yildiz Doviz Yet. Mues.", "WBO"],
            ["YTM", "YTM"],
            ["Ziraat", "ZRY"],
            ["Ziraat Bankasi", "TCZ"],
            ["Ziraat Bankasi (Portfoy Saklama)", "ZBS"],
            ["Ziraat Katilim Bankasi", "ZTB"]
          ];
    

          const [isimleri, setisimleri] = useState([]);

    
        const his_ara = async (hisse) => {
            console.log(hisse)
            
            setaranan(hisse.toUpperCase())
        
            if (aranan.length >= 2) {
            const arananHisseler = sirketadstam.filter((item) =>
            item.toUpperCase().startsWith(aranan)
        
        
          );
          setaramalistt(arananHisseler)
          console.log(arananHisseler)  
        
        }
          
          };










  return (








    <div className={styles.derinlikwindow}>
      <div className={styles.sectionParent}>
        
        <div className={styles.search}>
          <div className={styles.bg} />
          <input
  type="text"
  value={aranan}
  onChange={(e) => his_ara(e.target.value, isimleri)}
  placeholder={"Hisse Ara"}
  className={`${styles.text1}`}
  autoComplete="off"
  maxLength={6}
/>

          <img
            className={styles.vuesaxtwotonesearchNormalIcon}
            alt=""
            src="/vuesaxtwotonesearchnormal.svg"
          />
        </div>


        <div style={{
                            maxHeight: '750px', 
                            overflowY: 'auto', 
                            overflowX: 'hidden',
                            width: '100%',
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
                          }}>
                            <div style={{
                              overflowY: 'scroll',
                              height: '100%',
                              paddingRight: '20px',
                              boxSizing: 'content-box',
                              width: '100%'
                            }}>
        {aramalistt.length > 0 && (
        <div className={styles.currency}>
          <div className={styles.currencyInner}>
            <div className={styles.textParent}>
              <div className={styles.text2}>Arama Sonuçları ({aramalistt.length})</div>
              <div className={styles.frameParent}>

              {aramalistt.map((key, index) => (

                <div className={styles.myfinansLogo31Parent} onClick={(e) => { e.stopPropagation(); yeni_hisse(key); }}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${key || '/myfinanslogo3-1@2x'}.png`}
                    />
                  <div className={styles.frameContainer}>
                    <div className={styles.frameDiv}>
                      <div className={styles.textWrapper}>
                        <div className={styles.text3}>{key}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
        )}

{fveri.length >= 1 && (


        <div className={styles.frameGroup}>
          <div className={styles.textParagraphParent}>
            <div className={styles.textParagraph}>{secili}</div>
            <b className={styles.b}>{fveri[0].toFixed(2)} ₺</b>
            <div className={styles.div}>%{fveri[4].toFixed(2)}</div>
          </div>
          <div className={styles.al2800Container}>
            <p className={styles.gnI}>Açılış : ${hisveri.acilis || "-"}</p>
            <p className={styles.gnI}>Gün İçi : ₺{fveri[5]} - ₺{fveri[6]}</p>
            <p className={styles.gnI}>Teorik Eşleşme : ₺{fveri[1]} </p>
            <p className={styles.gnI}>İşlem Adeti : {Intl.NumberFormat('en-US').format(fveri[2])}</p>
            <p className={styles.gnI}>İşlem Hacmi : ₺{Intl.NumberFormat('en-US').format(fveri[3])}</p>
          </div>
        </div>

)}
        <div className={styles.ordersParent}>
          <div className={styles.orders}>
            <div className={styles.textGroup}>
              <div className={styles.text8}>A.E.</div>
              <div className={styles.text9}>A.Lot</div>
              <div className={styles.text8}>Alış</div>
            </div>
           
            {[0, 1, 2, 3, 4,5,6,7,8,9].map(i => (

            <div className={styles.textParent1}>
              <div className={styles.text8}>{derinlikveri[`q${i}`]}</div>
              <div className={styles.text12}>{derinlikveri[`v${i}`]}</div>
              <div className={styles.text8}>{derinlikveri[`b${i}`]?.toFixed(2) || '0.00'}</div>
            </div>
            ))}


          </div>
          <div className={styles.ordersWrapper}>
            <div className={styles.lines}>
              <div className={styles.textGroup}>
                <div className={styles.text8}>Satış</div>
                <div className={styles.text9}>S.Lot</div>
                <div className={styles.text8}>S.E.</div>
              </div>

              {[0, 1, 2, 3, 4,5,6,7,8,9].map(i => (


              <div className={styles.textParent1}>
                <div className={styles.text8}>{derinlikveri[`a${i}`]?.toFixed(2) || '0.00'}</div>
                <div className={styles.text42}>{derinlikveri[`w${i}`]}</div>
                <div className={styles.text8}>{derinlikveri[`k${i}`]}</div>
              </div>

              ))}
            
            </div>
          </div>
        </div>
        <div className={styles.textParent20}>
          <div className={styles.text68}>İşlemler</div>
          <div className={styles.frameParent1}>
            <div className={styles.textParent21}>
              <div className={styles.text69}>Saat</div>
              <div className={styles.text70}>Fiyat</div>
              <div className={styles.text71}>Lot</div>
              <div className={styles.text72}>Alan</div>
              <div className={styles.text73}>Satan</div>
            </div>
            <div className={styles.line} />
            <div className={styles.lines}>

            {alansatan.map((item, index) => (


              <div className={styles.textParent1}>
                <div className={styles.text74}>{formattedDatetw(item.d)}</div>
                <div className={styles.text8}>{(item.p).toFixed(2)}</div>
                <div className={styles.text8}>{item.a}</div>
                <div className={styles.text77}>{getFullName((item.bc).slice(0, -2))}</div>
                <div className={styles.text78}>{getFullName((item.sc).slice(0, -2))}</div>
              </div>
            ))}
            </div>
          </div>
        </div>

</div>
</div>

      </div>
    </div>
  );
};

export default DerinlikWindow;
