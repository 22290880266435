import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import AnaSayfa from "./pages/AnaSayfa";
import Derinlik from "./pages/Derinlik";
import GirisYap from "./pages/GirisYap";
import KayitOl from "./pages/KayitOl";
import Hisseici from "./pages/HisseIci";
import Test from "./pages/test";
import Main from "./pages/main";
import MainMobil from "./pages/main_mobil";
import GirisYapMobil from "./pages/giris_mobil";
import KayitMobil from "./pages/kayitmobil";
import Mobilindir from "./pages/mobilindir";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/derinlik":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/giris" element={isMobile ? <GirisYapMobil /> : <GirisYap />} />
      <Route path="/panel"  element={isMobile ? <Mobilindir /> : <Test />} />
      <Route path="/kayit" element={isMobile ? <KayitMobil /> : <KayitOl />} />
      <Route path="/test" element={<Test />} />
      <Route path="/" element={isMobile ? <MainMobil /> : <Main />} />
    </Routes>
  );
}
export default App;
